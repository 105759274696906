
import { IonHeader, IonToolbar, IonButton, IonButtons, IonIcon } from "@ionic/vue";
import { ref, onMounted, defineComponent } from "vue";
import Slides from "./Slides.vue";
import BaseLayout from "./BaseLayout.vue";
import { close } from "ionicons/icons";
import translate from "../views/translate";
import { SlideContent } from "@/types/types";

export default defineComponent({
    name: "ModalWithSlides",
    components: { Slides, BaseLayout, IonHeader, IonToolbar, IonButton, IonButtons, IonIcon },
    props: {
        dismiss: { type: Function },
        slideContents: { type: Array as () => Array<SlideContent> },
        shouldShowCalculatorIconOnInstance: {type: Boolean, default: true}
    },
    setup() {
        const showSlides = ref(false);
        onMounted(() => {
            // this setTimeout is needed due to a deadlock with loading the slides and modal at the same time :/
            setTimeout(() => {
                showSlides.value = true;
            }, 100);
        });
        const { t } = translate();
        
        return { showSlides, t, close };
    },
});
