import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menu = _resolveComponent("Menu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, {
    dir: _ctx.direction,
    "data-html2canvas-ignore": "",
    onIonModalWillPresent: _ctx.pauseAudio,
    onIonModalWillDismiss: _ctx.pauseAudio,
    onIonActionSheetWillPresent: _ctx.pauseAudio,
    onIonWillOpen: _ctx.pauseAudio
  }, {
    default: _withCtx(() => [
      (_ctx.currentPath !== '/calculator')
        ? (_openBlock(), _createBlock(_component_Menu, {
            key: 0,
            imgSrc: _ctx.imageForHeader,
            pageTitle: _ctx.textForHeader
          }, null, 8, ["imgSrc", "pageTitle"]))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_router_outlet)
    ]),
    _: 1
  }, 8, ["dir", "onIonModalWillPresent", "onIonModalWillDismiss", "onIonActionSheetWillPresent", "onIonWillOpen"]))
}