import { WebPlugin } from '@capacitor/core';
import {
  DeleteAppPluginPlugin,
  ImportJourneySetPluginPlugin,
  ShareAppPluginPlugin,
  StorageLocationPickerPlugin,
} from './definitions';

export class DeleteAppPluginWeb extends WebPlugin implements DeleteAppPluginPlugin {
  constructor() {
    super({
      name: 'DeleteAppPlugin',
      platforms: ['web'],
    });
  }

  async echo(options: { value: string }): Promise<{ value: string }> {
    return options;
  }
  
  async deleteApp(): Promise<void> {
    return;
  }
}

export class StorageLocationPickerPluginWeb extends WebPlugin implements StorageLocationPickerPlugin {
  constructor() {
    super({
      name: 'StorageLocationPickerPlugin',
      platforms: ['web'],
    });
  }
  
  async save(options: { filename: string; content: string; plainText: boolean; mimeType: string; useDeviceApk: boolean }): Promise<{ uri: string }> {
    return {uri: ''};
  }
  async delete(options: { uris: string[] }): Promise<void> {
    return;
  }
  async navigate(options: { mimeType: string }): Promise<{ uri: string }> {
    return {uri: ''};
  }
}

export class ShareAppPluginWeb extends WebPlugin implements ShareAppPluginPlugin {
  async shareApp(options: {appName: string}) {
    return;
  }
}

export class ImportJourneySetPluginWeb extends WebPlugin implements ImportJourneySetPluginPlugin{
  constructor() {
    super({
      name: 'ImportJourneySetPlugin',
      platforms: ['web'],
    });
  }
  
  async resetUri(): Promise<void> { 
    return; 
  }
  async getJourneySetToImport(): Promise<{ uri: string }> {
    return {uri: ''};
  }
  async getFilename(): Promise<{ filename: string }> {
    return {filename: ''};
  }
}