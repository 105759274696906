
import { IonPage, IonProgressBar } from "@ionic/vue";
import {store} from "@/store";
import {computed} from "vue";

export default {
    name: "Loading",
    components: {
        IonPage,
        IonProgressBar
    },
    setup() {
        return {
            progress: computed(() => store.getters.getLoadingValue()),
            invalidUrl: computed(() => store.getters.getIsInvalidUrl())
        }
    }
}
