import { createApp } from 'vue'
import { store } from './store';
import App from './App.vue'
import {createI18n} from 'vue-i18n';
import router from './router';
import { IonicVue } from '@ionic/vue';
import BaseLayout from './components/BaseLayout.vue';
import './delete-app-plugin/web';
import './delete-app-plugin/definitions';
import {reloadManifestMetaData, savePreferences} from '@/storage/DataStorage';
import VueMarkdownIt from 'vue3-markdown-it';
import {getLocaleMessages} from "@/storage/DataStorage";
import Loading from "@/Loading.vue";

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/custom.css';
import {getImageDataUri} from "@/utilities/Compatibility";
import {SplashScreen} from '@capacitor/splash-screen';

//This is to fix a Safari bug with indexedDB - https://www.techradar.com/news/apple-safari-update-is-causing-chaos-with-web-apps
window.indexedDB;
store.dispatch("setCheckForVersion", true);
const loadingApp = createApp(Loading);
loadingApp.use(store);
window.document.getElementById("spinnerContainer")?.remove();
SplashScreen.hide();
loadingApp.mount('#app');
store.dispatch("setLoadingValue", .1);

const app = createApp(App);
app.use(store);
app.use(IonicVue, {swipeBackEnabled:false});
app.use(router);
app.use(VueMarkdownIt);

app.component('base-layout', BaseLayout);

const setHomeRoute = async () => {
    store.dispatch("setLoadingValue", .9);
    let messages = store.getters.getLocaleMessages();
    if (!messages.application) {
        const languageData = await getLocaleMessages();
        await store.dispatch("setLocale", languageData.locale);
        await store.dispatch("setMessages", languageData.messages);
        messages = languageData.messages;
    }
    
    const preferences = store.getters.getPreferences();
    const isFirstTimeOpeningApp = store.getters.isFirstTimeOpeningApp();
    await store.dispatch("setHeaderImage", getImageDataUri(messages.application.homeScreenIcon));
    const { pinScreenOn, alternatePinScreen }  = messages.buildConfigs.config;
    if (isFirstTimeOpeningApp) {
        preferences.shouldUseSafetyCalculator = pinScreenOn === "true";
        await store.dispatch("setPreferences", preferences);
    } else {
        await savePreferences(preferences);
        await reloadManifestMetaData();
    }
    
    if (alternatePinScreen === "true")
        router.addRoute({
            path: "/calculator",
            name: "calculator",
            component: () => import("@/views/Calculator/Calculator_Alt.vue"),
        });
    if (preferences.shouldUseSafetyCalculator) {
        router.addRoute({
            path: "/",
            name: "home",
            redirect: "/calculator"
        });
    }
    else {
        store.dispatch("setShouldShowCalculatorIcon", false);
        router.addRoute({
            path: "/",
            name: "home",
            redirect: "/fields"
        });
    }

    if (isFirstTimeOpeningApp || preferences.shouldUseSafetyCalculator) {
        await router.replace({name: "home"});
    } else {
        await router.replace({path: `${preferences.lastVisitedRoute}`});
    }
}

router.isReady().then(async () => {
    await setHomeRoute();
    const locale = store.getters.getLocale();
    const messages = store.getters.getLocaleMessages();
    const i18n = createI18n({
        legacy: false,
        locale: locale,
        messages: messages
    });
    app.use(i18n);

    store.dispatch("setLoadingValue", 1);

    // change from default of white after loaded 
    // (to keep the mobile device boot screen white until loaded)
    window.document.body.style.backgroundColor = "var(--color-app-excess-background)";
    
    app.mount('#app');
});