export interface SlideContent {
    imageSrc?: string;
    contentText: string;
    audioSrc?: string;
}

export enum FileIdentifiers {
    Audio = "$D1@-",
    Pdf = "$D2@-",
    JourneySet = "$D3@-",
    Apk = "$D4@-"
}

export enum MimeTypes {
    APK = 'application/vnd.android.package-archive',
    AUDIO = 'audio/mpeg',
    TEXT = 'text/plain',
    PDF = 'application/pdf'
}
