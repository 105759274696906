
import {actionSheetController, menuController, IonApp, IonRouterOutlet, useBackButton} from "@ionic/vue";
import { defineComponent, ref, watch, computed, onMounted, onBeforeMount } from "vue";
import {useRoute, useRouter} from "vue-router";
import { useStore } from "vuex";
import translate from "./views/translate";
import Menu from "@/components/Menu.vue";
import {reloadLanguage, savePreferences} from './storage/DataStorage';
import {openOnboardingPagesModal} from "@/services/InformationPagesService";
import {checkForJourneySetImport} from "@/utilities/JourneySet";
import {getImageDataUri} from "@/utilities/Compatibility";
import {AlertBuilder} from "@/utilities/AlertBuilder";
import {handleAndroidFileSelectToDelete} from "@/utilities/Filesystem";
import {MimeTypes} from "@/types/types";
import {App as capacitorApp} from '@capacitor/app';

export default defineComponent({
    name: "App",
    components: {
        IonRouterOutlet,
        Menu,
        IonApp,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const { t } = translate();
        
        const currentPath = ref(route.path);
        const currentPathName = ref(route.name);
        const imageForHeader = computed(() => store.state.headerImage);
        const direction = computed(() => t("messages.language.textDirection"));
        const textForHeader = computed(() => store.state.headerTitle);
        
        function initColors() {
            window.document.body.style.setProperty('--color-primary', `rgb(${t("messages.application.primaryColor")})`);
            window.document.body.style.setProperty('--color-primary-rgb', t("messages.application.primaryColor"));
            window.document.body.style.setProperty('--quadrant-field-1-background', t("messages.application.quadrantColors.quadrant1"));
            window.document.body.style.setProperty('--quadrant-field-2-background', t("messages.application.quadrantColors.quadrant2"));
            window.document.body.style.setProperty('--quadrant-field-3-background', t("messages.application.quadrantColors.quadrant3"));
            window.document.body.style.setProperty('--quadrant-field-4-background', t("messages.application.quadrantColors.quadrant4"));
            window.document.body.style.setProperty('--quadrant-field-5-background', t("messages.application.quadrantColors.quadrant5"));
            window.document.body.style.setProperty('--quadrant-field-6-background', t("messages.application.quadrantColors.quadrant6"));
        }

        function initSizes() {
            window.document.body.style.setProperty('--quadrant-font-size', t("messages.application.quadrantFontSize"));
            
            const journeyImgSize = t("messages.application.journeyImageSize");
            if (journeyImgSize === "medium") {
                window.document.body.style.setProperty('--journey-header-img-width', '30vh');
            } else if (journeyImgSize === "large") {
                window.document.body.style.setProperty('--journey-header-img-width', '50vh');
            } else {
                window.document.body.style.setProperty('--journey-header-img-width', '20vh');
            }
        }
        
        onBeforeMount(async () => {          
            const { setLocaleMessage, t } = translate();
            const locale = store.getters.getLocale();
            const messages = store.getters.getLocaleMessages();
            
            if (store.getters.isFirstTimeOpeningApp()) {
                await openOnboardingPagesModal(messages);
                await store.dispatch("setIsFirstTimeOpeningApp", false);
                await deleteInstallFile(messages);
            } 
            setLocaleMessage(locale, { messages: messages });
            
            // some header items need to be set here if they happen to be loading the app on these pages. this is due to having the images in the language file 
            if(route.name === 'security') {
                await store.dispatch("setHeaderImage", getImageDataUri(t("messages.securityPage.headerImage")))
            } else if(route.name === 'privacy'){
                await store.dispatch("setHeaderImage", getImageDataUri(t("messages.privacyPage.headerImage")))
            } else if(route.name === 'fields'){
                 await store.dispatch("setHeaderTitle", `${t("messages.application.appName")}`);
            }

            window.document.body.style.setProperty('--center-text-for-language', direction.value === 'ltr' ? 'center' : 'right' );
            initColors();
            initSizes();
        });
        
        onMounted(async () => {
            const preferences = store.getters.getPreferences();
            
            //Once the app is mounted, then store all preferences to the indexedDB
            //that may have been set or modified during initial app load
            await savePreferences(preferences);
            
            if (!preferences.shouldUseSafetyCalculator) {
                await checkForJourneySetImport();
            }
        })
        const shouldExitApp = computed(() =>store.getters.getShouldExitApp());
        capacitorApp.addListener("appStateChange", async (state: any) => {
            pauseAudio();
            actionSheetController.dismiss();
            menuController.close();
            if (!state.isActive && shouldExitApp.value) {
                const preferences = store.getters.getPreferences();
                if (preferences.shouldUseSafetyCalculator) {
                    router.push({ name: 'calculator'});
                }
                capacitorApp.exitApp();
            } else if(!state.isActive) {
                await store.dispatch("setShouldExitApp", true);
            } else if (state.isActive && store.getters.platform() === "ios") {
                await checkForJourneySetImport();
            }
        });

        watch(route, (currentRoute) => {
            store.dispatch("setPrevPage", currentPathName.value == "calculator" ? "fields" : currentPath.value);
            currentPath.value = currentRoute.path;
            currentPathName.value = currentRoute.name;
        });

        // this is a global handler for capturing android back buttons on the calculator screen
        useBackButton(10, (processNextHandler) => {
            if (!currentPath.value.includes("calculator")) {
                processNextHandler();
            }
        });

        function pauseAudio() {
            store.dispatch("setShouldPauseAudio", true);
        }
        
        async function deleteInstallFile(messages: any) {
            if (store.getters.platform() === "android" && messages.buildConfigs.config.appStoreBuild === "false") {
                const alert = new AlertBuilder('custom-alert', `<p>${ messages.application.deleteInstallFile }</p>`)
                    .addNoButton()
                    .addYesButton(async () => {
                        await handleAndroidFileSelectToDelete(MimeTypes.APK);
                    });
                await alert.present();
            }
        }

        return {
            currentPath,
            imageForHeader,
            textForHeader,
            direction,
            pauseAudio
        };
    },
});
