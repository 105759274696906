
import { IonSlides, IonSlide } from "@ionic/vue";
import {computed, defineComponent, onBeforeMount, ref} from "vue";
import { useStore } from 'vuex';
import { SlideContent } from "@/types/types";
import AudioPlayer from '@/components/AudioPlayer.vue';
import {createLoadSpinner} from "@/services/ShareService";

export default defineComponent({
    name: "Slides",
    components: {
        IonSlides,
        IonSlide,
        AudioPlayer
    },
    props: {
        slideContents: { type: Array as () => Array<SlideContent> },
    },
    setup(props) {
        const store = useStore();
        const slideFooter = ref();
        const showSlideContent = ref(false);
        const useOnLoadedMetadata = computed(() => {
            const isSafari = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1
            return isSafari || store.getters.platform() === 'ios';
        });
        const slideOpts = {
            initialSlide: 0,
            threshold: 12,
            pagination: {
                el: ".swiper-pagination",
                type: "bullets",
                clickable: true
            }
        };
        const slides = ref();
        const audioStartTime = ref(0);
        const audioEndTime = ref(0);
        const loadingSpinner = ref();
        const numVideos = props.slideContents?.filter((slide) => isVideo(slide.imageSrc ?? '')).length || 0;
        let numVideosLoaded = 0;

        onBeforeMount(async () => {
            if (numVideos > 0) {
                loadingSpinner.value = await createLoadSpinner(false);
                if (!showSlideContent.value) {
                    await loadingSpinner.value.present();
                }
            } else {
                showSlideContent.value = true;
            }
        })

        function pauseAudio() {
            store.dispatch("setShouldPauseAudio", true);
        }

        function metadataLoaded() {
            if (useOnLoadedMetadata.value) {
                videoLoaded();
            }
        }

        function fullyLoaded() {
            if (!useOnLoadedMetadata.value) {
                videoLoaded();
            }
        }

        function videoLoaded() {
            numVideosLoaded += 1;
            if (numVideos === numVideosLoaded) {
                showSlideContent.value = true;
                loadingSpinner.value?.dismiss();
            }
        }

        function isVideo(dataUrl: string) {
            return dataUrl.split(':')[1].split('/')[0] === 'video';
        }

        function getContentType(dataUrl: string) {
            return dataUrl.split(';')[0].split(':')[1];
        }

        return {
            videoLoaded,
            slideOpts,
            audioStartTime,
            audioEndTime,
            pauseAudio,
            slides,
            slideFooter,
            showSlideContent,
            useOnLoadedMetadata,
            metadataLoaded,
            fullyLoaded,
            isVideo,
            getContentType
        };
    },
});
