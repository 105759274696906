
import {
    IonContent,
    IonHeader,
    IonToolbar,
    IonMenu,
    IonTitle,
    IonMenuButton,
    IonButtons,
    IonItem,
    IonList,
    IonIcon,
    IonMenuToggle,
    menuController
} from "@ionic/vue";
import {computed, defineComponent, onBeforeMount, ref} from "vue";
import { useRouter } from "vue-router";
import { chevronDown, chevronUp, person, shareSocial, heart, documentTextSharp, closeSharp, homeSharp, leaf, save, cloudDownload, exit, sparkles } from "ionicons/icons";
import translate from "../views/translate";
import {
    createLoadSpinner,
    readAndSaveJourneySets
} from "@/services/ShareService";
import { openBigPicturePagesModal } from "@/services/InformationPagesService";
import { useStore } from "vuex";
import {downloadAPK, getFromS3} from "@/services/AWSService";
import { connectToInternetAlert} from "@/utilities/Alerts";
import {ActionSheetBuilder} from "@/utilities/ActionSheetBuilder";
import { AlertBuilder } from "@/utilities/AlertBuilder";
import {isValidJourneySetFilename, getJourneySetImportErrors} from "@/utilities/JourneySet";
import {getDBVersion} from "@/storage/DataStorage";
import {deleteAndroidFiles, deleteApp} from "@/utilities/Filesystem";
import {getImageDataUri} from "@/utilities/Compatibility";

export default defineComponent({
    name: "Menu",
    props: ["pageTitle", "imgSrc"],
    components: {
        IonContent,
        IonHeader,
        IonToolbar,
        IonMenu,
        IonTitle,
        IonItem,
        IonButtons,
        IonMenuButton,
        IonList,
        IonIcon,
        IonMenuToggle,
    },
    setup() {
        const { t } = translate();
        const store = useStore();
        const dynamicSide = computed(() => t("messages.language.textDirection") === "ltr" ? "end" : "start");
        const showUnencrypted = computed(() => process.env.NODE_ENV === "development");
        const expandFindMore = ref(false);
        const showSettings = ref(false);
        const showBigPicture = computed(() => t("messages.buildConfigs.config.bigPic") === "true");
        const showLearnMore = computed(() => t("messages.buildConfigs.config.learnMore") === "true");
        const showMoreTopics = computed(() => t("messages.buildConfigs.config.moreTopics") === "true");
        const showFindMore = computed(() => showBigPicture.value || showLearnMore.value || showMoreTopics.value);
        const platform = ref('');
        const isOfflineAndroid = computed(() => platform.value === "android" && t("messages.buildConfigs.config.appStoreBuild") !== "true")
        
        onBeforeMount(async () => {
            platform.value = await store.getters.platform();
        })

        const toggleFindMore = () => {
            showSettings.value = false;
            expandFindMore.value = !expandFindMore.value;
        };

        const toggleSettings = () => {
            expandFindMore.value = false;
            showSettings.value = !showSettings.value;
        };

        const isMenuOpen = ref(false);

        const toggleMenu = (value: boolean) => {
            isMenuOpen.value = value;
        };

        const closeSubMenus = () => {
            expandFindMore.value = false;
            showSettings.value = false;
        };
        const router = useRouter();
        const route = computed(() => router.currentRoute.value);

        const returnHome = async () => {
            await menuController.close();
            await router.push({ name: `fields` });
        };

        const goToPrivacy = () => {
            router.push({ name: `privacy` });
        };

        const goToLearnMore = () => {
            router.push({ name: "learn-more", params: { fieldId: "jf5" } });
        };

        const goToMoreTopics = () => {
            router.push({ name: "more-topics", params: { fieldId: "jf6" } });
        };

        const goToSecurity = () => {
            router.push({ name: `security` });
        };

        const goToCoach = () => {
            router.push({ name: `coach` });
        };
        
        const askToUpdate = async () => {
            const alert = new AlertBuilder('custom-alert', `<p>${t('messages.application.updateApp')}</p>`)
                .addNoButton()
                .addYesButton(async () => {
                    const loading = await createLoadSpinner();
                    await loading.present();
                    await downloadAPK(false);
                    await loading.dismiss();
                });
            await alert.present();
        }
        
        const checkForUpdates = async () => {
            const result = await getFromS3(t('messages.language.variant'), `appVersion.txt?timestamp=${new Date().getTime()}`, 'text');
            const mostRecentAppVersion = result.data;
            
            if (getDBVersion() < parseInt(mostRecentAppVersion, 10)) {
                await askToUpdate();
            } else {
                const alert = new AlertBuilder('custom-alert', `<p>${t('messages.application.noUpdatesAvailable')}</p>`).addOkButton();
                await alert.present();
            }
        }
        
        const checkForUpdatesPreCheck = async () => {
            const shouldDisplayInternetAlert = await connectToInternetAlert(async () => {
                await checkForUpdates();
            });
            if (!shouldDisplayInternetAlert) {
                await checkForUpdates();
            }
        }

        const setShouldExitApp = async () => {
            await store.dispatch("setShouldExitApp", false);
        };
        
        const importJourneySets = async (event: any, shouldDecrypt: boolean) => {
            await store.dispatch("setShouldExitApp", true);
            let showSuccessMessage = false;
            if (event.target.files.length > 0) {
                const loading = await createLoadSpinner();
                await loading.present();
                let error = '';
                for (const file of event.target.files) {
                    let fileError = '';
                    if (isValidJourneySetFilename(file.name)) {
                        try {
                            await new Promise((resolve, reject) => {
                                const reader = new FileReader();
                                reader.readAsText(file, "UTF-8");
                                reader.onload = async (setToBeAdded) => {
                                    try {
                                        const journeySet = JSON.parse(setToBeAdded?.target?.result as string);
                                        const importErrors = getJourneySetImportErrors(journeySet);
                                        if (importErrors === '') {
                                            await readAndSaveJourneySets(journeySet, shouldDecrypt);
                                            showSuccessMessage = true;
                                            resolve(true);
                                            return;
                                        }
                                        fileError = importErrors.replace('[%filename]', file.name);
                                        reject("invalid");
                                    } catch (e) {
                                        fileError = t('messages.application.importErrorProcessing').replace('[%filename]', file.name);
                                        reject("error");
                                    }
                                };
                                reader.onerror = function(evt) {
                                    fileError = t('messages.application.importErrorReading').replace('[%filename]', file.name);
                                    // TODO: MAYBE SOME AWS LOGGING SOMETIME DOWN THE ROAD?
                                    reject(evt?.target?.result);
                                };
                            });
                        } catch (e) {
                            await loading.dismiss();
                            fileError = fileError ?? t('messages.application.importErrorProcessing').replace('[%filename]', file.name);
                        }
                    } else {
                        error = t('messages.application.importInvalidFile').replace('[%filename]', file.name);
                    }
                    error += fileError ? fileError + '<br /><br />' : '';
                }
                if (error) {
                    const alert = new AlertBuilder('custom-alert', error).addCancelButton();
                    await alert.present();
                } else if (showSuccessMessage) {
                    const alert = new AlertBuilder('custom-alert', 
                `<p>${t("messages.application.successfulImport")}</p>
                        <p>${t("messages.application.deleteSet")}</p>`).addCancelButton();
                    await alert.present();
                    
                    // need to reload the page to get new journey sets
                    if (["journey-sets", "more-topics", "learn-more"].includes(route.value.name as string)) {
                        router.push({name: 'fields'});
                    }
                }
                await loading.dismiss();
            }
            event.target.value = '';
        };

        async function handleShareApp() {
            const actionSheetBuilder = new ActionSheetBuilder('');
            await actionSheetBuilder.buildShareOrSaveApp();
            const actionSheet = await actionSheetBuilder.create();
            await actionSheet.present();
        }
        
        return {
            t,
            toggleFindMore,
            expandFindMore,
            toggleSettings,
            showSettings,
            chevronDown,
            chevronUp,
            person,
            shareSocial,
            heart,
            save,
            documentTextSharp,
            toggleMenu,
            isMenuOpen,
            closeSharp,
            closeSubMenus,
            homeSharp,
            returnHome,
            goToPrivacy,
            goToLearnMore,
            goToMoreTopics,
            goToCoach,
            goToSecurity,
            leaf,
            openBigPicturePagesModal,
            localeMessages: computed(() => store.getters.getLocaleMessages()),
            importJourneySets,
            setShouldExitApp,
            showFindMore,
            showBigPicture,
            showLearnMore,
            showMoreTopics,
            shareAppText: computed(() => platform.value === "ios" || store.getters.getLocaleMessages().buildConfigs.config.appStoreBuild === 'true' ? t("messages.application.shareAppOnly"): t("messages.menu.shareApp")),
            handleShareApp,
            dynamicSide,
            showUnencrypted,
            cloudDownload,
            checkForUpdatesPreCheck,
            isOfflineAndroid,
            showAllQuadrants: computed(() => store.getters.showAllQuadrants()),
            platform,
            exit,
            sparkles,
            deleteApp,
            deleteAndroidFiles,
            bigPictureIcon: computed(() => getImageDataUri(store.getters.getLocaleMessages().menu.bigPictureIcon)),
            moreTopicsIcon: computed(() => getImageDataUri(store.getters.getLocaleMessages().menu.moreTopicsIcon)),
            learnMoreIcon: computed(() => getImageDataUri(store.getters.getLocaleMessages().menu.learnMoreIcon))
        };
    },
});
