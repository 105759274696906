import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b64057a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sub-header" }
const _hoisted_2 = { class: "sub-header__icon-row" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, { id: "main-content" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, {
        fullscreen: true,
        class: _normalizeClass(["base-content", $setup.directionClass])
      }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ion_icon, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.routeBack())),
                icon: $setup.chevronBack,
                class: "sub-header__icon back-button"
              }, null, 8, ["icon"]),
              _withDirectives(_createVNode(_component_ion_icon, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('share'))),
                icon: $setup.shareSocial,
                class: "sub-header__icon"
              }, null, 8, ["icon"]), [
                [_vShow, $props.shouldShowShareIcon]
              ]),
              ($props.shouldShowHelpIcon)
                ? (_openBlock(), _createBlock(_component_ion_icon, {
                    key: 0,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('help'))),
                    icon: $setup.help,
                    class: "sub-header__icon"
                  }, null, 8, ["icon"]))
                : _createCommentVNode("", true)
            ]),
            ($props.subHeaderText.length > 0)
              ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString($props.subHeaderText), 1))
              : _createCommentVNode("", true)
          ], 512), [
            [_vShow, $props.shouldShowSubHeader]
          ]),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true),
          ($props.shouldShowCalculatorIconOnInstance && $setup.shouldShowCalculatorIcon)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.routeToCalculator())),
                class: "calculator-container",
                ref: "calculatorContainer"
              }, [
                _createVNode(_component_ion_icon, { icon: $setup.keypad }, null, 8, ["icon"])
              ], 512))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["class"])
    ]),
    _: 3
  }))
}