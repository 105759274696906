
import { IonContent, IonPage, IonIcon, modalController } from "@ionic/vue";
import { useRouter } from "vue-router";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { chevronBack, help, shareSocial, keypad } from "ionicons/icons";
import translate from "@/views/translate";
import {correctFixedPositionOnRotate} from "@/utilities/Styling";

export default {
    name: "BaseLayout",
    props: {
        returnRoute: {
            type: String,
            default: "",
        },
        shouldShowSubHeader: {
            type: Boolean,
            default: true,
        },
        shouldShowShareIcon: {
            type: Boolean,
            default: false,
        },
        shouldShowHelpIcon: {
            type: Boolean,
            default: false,
        },
        shouldShowCalculatorIconOnInstance: {
            type: Boolean,
            default: true,
        },
        subHeaderText: {
            type: String,
            default: ""
        }
    },
    components: {
        IonContent,
        IonPage,
        IonIcon,
    },
    setup(props: any) {
        const router = useRouter();
        const store = useStore();
        const { t } = translate();
        const calculatorContainer = ref();
        const dismissModal = async () => {
            await modalController.dismiss();
        };
        const routeToCalculator = async () => {
            const isModalOnScreen = (await modalController.getTop()) !== undefined;
            if (isModalOnScreen) {
                await dismissModal();
            }
            router.push({ name: "calculator" });
        };
        const directionClass = computed(() => t("messages.language.textDirection"));
        const routeBack = async () => {
            router.push(props.returnRoute);
        };
        const shouldShowCalculatorIcon = computed(() => store.state.shouldShowCalculatorIcon);

        onMounted(() => {
            window.addEventListener('resize', () => correctFixedPositionOnRotate(calculatorContainer.value));
        });
        
        return { 
            routeToCalculator, 
            directionClass, 
            chevronBack, 
            shareSocial, 
            help, 
            routeBack, 
            shouldShowCalculatorIcon,
            keypad,
            calculatorContainer
        };
    },
};
