export function getVideoDataUri(content: string | undefined) {
    return getDataUri('data:video/mp4;base64', content);
}

export function getImageDataUri(content: string | undefined) {
    return getDataUri('data:image/svg+xml;base64', content);
}

export function getAudioDataUri(content: string | undefined) {
    return getDataUri('data:audio/mp3;base64', content);
}

function getDataUri(prefix: string, content: string | undefined) {
    if (!content) {
        return '';
    }
    return content.startsWith('data:') ? content : `${prefix}, ${content}`;
}