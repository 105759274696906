import AES from "crypto-js/aes";
import CryptoJS from "crypto-js/core";

const KEY = "8cc44c04f1b3fd0ce243a84e709027e0";

export function decrypt(value: any): any {
    return AES.decrypt(value, KEY);
}

export function encrypt(value: any): any {
    return AES.encrypt(value, KEY);
}

export function encryptEntry(objectToEncrypt: any, arrayToEncrypt = [] as any, keyNameForArray = ""): any {
    let encryptedObject = {} as any;

    for (const key in objectToEncrypt) {
        if (key !== keyNameForArray) {
            if (key === "tabContentImages" || key === "tabImages") {
                const encryptyedValue = encrypt(JSON.stringify(objectToEncrypt[key])).toString();
                encryptedObject = { ...encryptedObject, [encrypt(key).toString()]: encryptyedValue };
            } else if (key !== "id" && key !== "journeySetId" && key !== "quadrantId" && key !== "js89sd8lsDKf3" && key !== 'locale') {
                if (objectToEncrypt[key] !== undefined) {
                    const encryptyedValue = encrypt(objectToEncrypt[key]?.toString()).toString();
                    encryptedObject = { ...encryptedObject, [encrypt(key).toString()]: encryptyedValue };
                }
            } else {
                encryptedObject = { ...encryptedObject, [key]: objectToEncrypt[key] };
            }
        }
    }

    if (arrayToEncrypt.length > 0) {
        let encryptedArray = [] as any;
        for (const currentTab of arrayToEncrypt) {
            let encryptedObjectForArray = {};
            for (const key in currentTab) {
                if (currentTab[key]) {
                    const encryptyedValue = encrypt(currentTab[key].toString()).toString();
                    encryptedObjectForArray = { ...encryptedObjectForArray, [encrypt(key).toString()]: encryptyedValue };
                }
            }
            encryptedArray = [encryptedObjectForArray, ...encryptedArray];
        }
        encryptedObject = { ...encryptedObject, [keyNameForArray]: encryptedArray };
    }
    return encryptedObject;
}

export function decryptEntry(objectToDecrypt: any, arrayToDecrypt = [] as any, keyNameForArray = "") {
    let decryptedObject = {} as any;
    for (const key in objectToDecrypt) {
        if (key !== keyNameForArray) {
            if (key !== "id" && key !== "journeySetId" && key !== "quadrantId" &&  key !== "js89sd8lsDKf3" && key !== 'locale') {
                if (objectToDecrypt[key]) {
                    let decryptedValue = decrypt(objectToDecrypt[key]).toString(CryptoJS.enc.Utf8);
                    if (decryptedValue === "false" || decryptedValue === "true") {
                        decryptedValue = JSON.parse(decryptedValue);
                    } else if (["order", "tabContentImages", "tabImages"].includes(decrypt(key).toString(CryptoJS.enc.Utf8))) {
                        decryptedValue = JSON.parse(decryptedValue);
                    }
                    decryptedObject = { ...decryptedObject, [decrypt(key).toString(CryptoJS.enc.Utf8)]: decryptedValue };
                }
            } else {
                decryptedObject = { ...decryptedObject, [key]: objectToDecrypt[key] };
            }
        }
    }
    if (arrayToDecrypt.length > 0) {
        let decryptedArray = [] as any;
        for (const currentIndex of arrayToDecrypt) {
            let decryptedObjectForArray = {};
            for (const key in currentIndex) {
                if (currentIndex[key]) {
                    const decryptedValue = decrypt(currentIndex[key]).toString(CryptoJS.enc.Utf8);
                    decryptedObjectForArray = { ...decryptedObjectForArray, [decrypt(key).toString(CryptoJS.enc.Utf8)]: decryptedValue };
                }
            }

            decryptedArray = [decryptedObjectForArray, ...decryptedArray];
        }
        decryptedObject = { ...decryptedObject, [keyNameForArray]: decryptedArray };
    }
    return decryptedObject;
}
